import React from 'react';
import { NavigationBar } from './navigationBar';
import { Modals } from './modal';
import { ScriptCDN } from './scriptCDN';
import { resources } from './locales.js'

export function AboutContent({ lang }) {
    return (
        <div>
            <NavigationBar lang={lang}></NavigationBar>

            <div class="container h-100" >

                <div class="row  justify-content-center align-self-center">
                    <div class="col-12 col-xs-12 col-sm-10 col-md-10 mx-auto mt-5 pt-5 text-center">
                        <h1 class="text-zer">{resources[lang]["about"]["message"]}</h1>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-xs-12 col-sm-12 col-md-12 mx-auto pt-5 text-center">
                        &nbsp;
                    </div>
                </div>
                <div class="row mt-1">

                    <div class="col-12 col-xs-12 col-sm-12 col-md-5 mx-auto pt-5">
                        <div class="alert alert-sponsor">
                            <h5>{resources[lang]["about"]["promoter"]}</h5>
                            <hr/>
                            <p class="pt-2 pb-2 pl-3 pr-3  text-center">
                            <a href="http://www.vicomtech.org" target="_blank"><img src="/img/vicomtech_logo.svg" width="180em" /></a></p>

                            <br/>

                            <h5>{resources[lang]["about"]["partners"]}</h5>
                            <hr/>
                            <p class=" text-center">
                            <a href="https://www.euskaltel.com" target="_blank"><img src="/img/euskaltel.svg" width="200em" /></a>{" "}
                            <a href="https://www.mondragonlingua.com" target="_blank"><img src="/img/mondragonlingua.png" width="200em" /></a>
                            <br/>
                            <a href="https://www.eitb.eus" target="_blank"><img src="/img/eitb.svg" width="150em" /></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Modals lang={lang}></Modals>
            <ScriptCDN lang={lang} page="about"></ScriptCDN>

        </div>
    );
}
